import PublicDashboardTitle from "../Components/Title";
import PublicDashboardMono from "./Mono";

import PublicDashboardCardTable from "../Components/CardTable";
import PublicDashboardOrderTable from "../Components/PaymentTable";
import PublicDashboardCardLogs from "../Components/CardLogs";
// import PublicDashboardRefPaymentTable from "../Components/RefPaymentTable";
import { useContext } from "react";
import AuthContext from '../../../../context/auth';
import AdsComponent from '../../../../components/GoogleAds/GoogleAds';

// import { InfoAlert } from "../../../../components/Alert/Alert";
// import { Diwali } from "../../../../components/Alert/Festival";
import { Whatsapp } from "../../../../components/Alert/whatsapp";
import Branding, { Settings } from "../../../../Branding/Branding";

function PublicDashboard() {

    const { user } = useContext(AuthContext);
    const branding = Branding() as Settings;


    return (

        <>
            <PublicDashboardTitle title={"Dashboard"} icon={"bi bi-house-door fa-fw me-1"} addcardbtn={true} />
            {/* < Diwali /> */}
            {
                !branding.refadmin && < Whatsapp />
            }
            


            <PublicDashboardMono />

            <PublicDashboardCardLogs />

            <PublicDashboardCardTable />

            <div className="col-12 mb-4">
                <AdsComponent dataAdSlot="5824067931" />
            </div>

            {
                (!user.acquired_by && !user.is_subadmin) && <PublicDashboardOrderTable />
            }


        </>

    );
}


export default PublicDashboard;